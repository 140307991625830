<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Incidencias"
      ref="pageForm"
      :items="items"
      :isAdmin="isItForAdmin"
    >
      <template>
        <Form
          :typeform="typeform"
          @closeModa="closeModa"
          @getData="getData()"
        />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6 d-inline-flex align-items-center">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center m-0">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                >
                  <template #cell(actions)="data">
                    <div class="action-btn-container">
                      <b-dropdown variant="link" no-caret>
                        <template #button-content>
                          <i class="fa fa-fw fa-bars font-size-16" />
                        </template>
                        <div v-if="isAdmin">
                          <b-dropdown-item @click="edit(data.item)">
                            <i class="uil-edit m-2 font-size-18" />
                            <span class="align-middle ml-5">EDITAR</span>
                          </b-dropdown-item>
                        </div>
                        <div v-if="isAdmin">
                          <b-dropdown-item @click="remove(data.item)">
                            <i class="uil-trash m-2 font-size-18" />
                            <span class="align-middle ml-5">ELIMINAR</span>
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <div class="row" v-if="tableData.length">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"
                    ></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="
                        tableData.length > limit || tableData.length < limit
                      "
                    ></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Layout from '../../layouts/main';
import Form from './components/form.vue';
import { PageHeader } from '../../../components';
import { breadCrumbData } from '../../../helpers/breadcrumbs/breadCrumbs';
import Swal from 'sweetalert2';

export default {
  components: { Layout, Form, PageHeader },
  data() {
    return {
      userRole: '',
      items: breadCrumbData.incidents,
      typeform: {},
      title: this.$t('sidebar.incidents').toUpperCase(),
      // TABLE
      // fields: [],
      // tableData: [],
      pageOptions: [1, 10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      filter: null,
      filterOn: [],
      loading: false,
      limit: 10,
      skip: 0,
      // END TABLE

      // FILTER
      filteOptions: {},
    };
  },
  computed: {
    ...mapGetters({
      tableData: 'providerErrors/getData',
      fields: 'providerErrors/getFields',
      isAdmin: 'user/isAdmin',
      success: 'providerErrors/getSuccess',
      alert_title: 'providerErrors/getAlertMessage',
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  methods: {
    getUserRole() {
      this.userRole = this.$store.getters['user/getRole'];
    },
    closeModa() {
      this.typeform = {};

      this.$refs.pageForm.modal = false;

      this.filterData();
    },
    async filterData() {
      this.loading = true;
      const options = {
        ...this.filteOptions,
        limit: this.limit,
        page: this.currentPage,
      };

      await this.getData(options);

      this.loading = false;
      // this.tableData = this.providerErrors;
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.filterData();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.filterData();
    },

    edit(item) {
      this.typeform = { ...item };
      this.$refs.pageForm.modal = true;
    },
    async remove(item) {
      const { isConfirmed } = await Swal.fire({
        title: 'Estas seguro en remover la incidendia?',
        showCancelButton: true,
      });

      if (!isConfirmed) return;

      await this.removeData(item._id);

      if (!this.success)
        return Swal.fire({
          title: this.alert_title,
          icon: 'error',
          timer: 3000,
        });

      Swal.fire({
        title: this.alert_title,
        icon: 'success',
        timer: 3000,
      });

      this.filterData();
    },
    ...mapActions({
      getData: 'providerErrors/fetchErrorCodesPaginated',
      removeData: 'providerErrors/remove',
    }),
  },
  watch: {
    perPage() {
      this.currentPage = 1;
      this.skip = 0;
      this.limit = this.perPage;
      this.filterData();
    },
  },
  mounted() {
    this.getUserRole();
    this.filterData();
  },
};
</script>

<style lang="scss" scoped>
.action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
}
</style>
